.console-text {
  background-color: rgb(36, 36, 36); /* Light transparent black background */
  color: rgb(236, 243, 236); /* Light greenish text color */
  font-family: "Courier New", Courier, monospace; /* Monospace font for console-like appearance */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  box-sizing: border-box; /* Includes padding and border in width */
}

.window-controls {
  position: absolute; /* Position the controls absolutely relative to the parent container */
  top: 10px; /* Position from the top of the container */
  left: 10px; /* Position from the left of the container */
}

.window-controls span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
}

.close {
  background-color: #fc605b;
}

.minimize {
  background-color: #fecb2f;
}

.maximize {
  background-color: #2aca3e;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking-cursor {
  display: inline-block;
  width: 2px;
  background-color: currentColor;
  animation: blink 1s step-start infinite;
  vertical-align: middle;
}
