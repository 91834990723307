:root {
  --background-color: #f3f4f6;
  --text-color: #2c2f33;
  --link-color: #61dafb;
  --header-background-color: #282c34;
  --header-text-color: #ffffff;
  --animation-duration: 20s;
}

body[data-theme="dark"] {
  --background-color: #153241;
  --text-color: #ffffff;
  --header-background-color: #1c252b;
  --header-text-color: #ffffff;
}

body[data-theme="light"] {
  --background-color: #f3f4f6;
  --text-color: #2c2f33;
  --header-background-color: #282c34;
  --header-text-color: #ffffff;
}

.App {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite var(--animation-duration) linear;
}

.App-header {
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App-link {
  color: var(--link-color);
  transition: color 0.2s ease-in-out;
}

.App-link:hover,
.App-link:focus {
  color: darken(var(--link-color), 10%);
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: reduce) {
  .App-logo {
    animation: none;
  }
}

@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .App-logo {
    height: 30vmin;
  }
}

.content {
  display: flex;
  flex-direction: column; /* Adjusted for better mobile responsiveness */
}

main {
  padding: 20px;
  flex-grow: 1;
}

.section-hidden {
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.section-visible {
  opacity: 1;
  transform: translateY(0px) scale(1);
  transition: opacity 0.7s ease-out, transform 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (prefers-reduced-motion: reduce) {
  .section-hidden,
  .section-visible {
    transition: none;
  }
}
