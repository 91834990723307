body.theme-change-animation {
  transition: background-color 0.3s ease, color 0.3s ease;
}



.section-hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}

.section-visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}


/* Base styles for the hamburger menu */
.hamburger {
  cursor: pointer;
  width: 24px; /* Width of the hamburger menu */
  height: 24px; /* Height of the hamburger menu */
  position: relative; /* Relative position for absolute positioning of lines */
  display: flex; /* Use flex layout to center lines */
  align-items: center; /* Align lines vertically */
  justify-content: center; /* Center lines horizontally */
}

.hamburger-box {
  width: 24px;
  height: 24px;
  position: relative;
}


.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: currentColor;
  position: absolute;
  width: 100%;
  height: 2px;
  transition: all 0.3s ease-in-out;
  display: block;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  left: 0;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/* Animation for opening */
.hamburger.open .hamburger-inner {
  background-color: transparent;
}

.hamburger.open .hamburger-inner::before {
  transform: translateY(10px) rotate(45deg);
}

.hamburger.open .hamburger-inner::after {
  transform: translateY(-10px) rotate(-45deg);
}



.drawerSlideIn {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  transition: visibility 0s, opacity 0.3s linear, transform 0.3s ease-out;
}

.drawerSlideOut {
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%);
  transition: visibility 0s 0.3s, opacity 0.3s linear, transform 0.3s ease-in;
}

.menuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1299; /* Just below the drawer */
  cursor: pointer;
}

.title {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.title.openEffect {
  opacity: 0.5; /* Adjust opacity to make the effect noticeable */
}

/* Navbar.css */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.hamburger.open .hamburger-box .hamburger-inner {
  animation: pulse 1s infinite;
}

.led-effect {
  transition: color 0.3s, text-shadow 0.3s;
}

/* LED glow effect on hover */
.led-effect:hover {
  color: #FFF; /* Fallback to white text */
  text-shadow:
    0 0 5px #10f3e8, /* Glowing effect */
    0 0 10px #10f3e8,
    0 0 15px #10f3e8,
    0 0 20px #10f3e8,
    0 0 25px #10f3e8,
    0 0 30px #10f3e8;
}

/* If you have other specific styles for dark mode, you can adjust them accordingly */
.theme-dark .led-effect {
  color: #10f3e8; /* Use the same color for the text in dark mode */
}

.theme-dark .led-effect:hover {
  /* You can keep the same glow color or use white for the glow */
  text-shadow:
    0 0 5px #10f3e8, /* Glowing effect with the given color */
    0 0 10px #10f3e8,
    0 0 15px #10f3e8,
    0 0 20px #FFF, /* Optionally add white for a mixed glow */
    0 0 25px #FFF,
    0 0 30px #FFF;
}

/* Flip effect */
.flip-effect {
  display: inline-block; /* Allows the flip effect */
  transition: transform 0.6s; /* Smooth flip effect */
  transform-style: preserve-3d; /* Needed for flip effect */
}

/* Apply transitions to elements that change with the theme */
.nav-item, .drawerLight, .drawerDark, .led-effect {
  transition: background-color 0.3s ease, color 0.3s ease;
}

