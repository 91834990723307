/* Reset margins, padding, and box-sizing */
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Custom scroll bar */
::-webkit-scrollbar {
    width: 5pt; /* Adjust scrollbar width */
    height: 5pt; /* Adjust scrollbar height for horizontal scrolling */
}

::-webkit-scrollbar-track {
    background: #070202; /* Track color */
}

::-webkit-scrollbar-thumb {
    background-color: #0fe3eb; /* Thumb color */
    border-radius: 10px; /* Rounded corners for thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff; /* Thumb color on hover */
}
